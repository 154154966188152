// Utility Color Classes

//.success {
//
//}

// Google Color Palette defined: http://www.google.com/design/spec/style/color.html

$grey: #666;
$smoke: #ebebeb;
$smooth: #999;
$coffee: #000;
$white: #fff;
$black: #333;

$light-black: #1a1a1a;
$dark-black: #0d0d0d;
$smooth-black: #4d4d4d;
$black-2: #666666;

$gold: hsla(45, 60%, 47%, 1);
$burgundy: hsla(345, 100%, 30%, 1);

$teal: hsla(176, 38%, 67%, 1);
$plum: hsla(228, 13%, 36%, 1);
$salmon: hsla(11, 54%, 84%, 1);

$yellow: hsla(54, 100%, 55%, 1);

$gold: #bf9b30;

$gold2: #CBB376;
$gold3: #C29C5E;